/**
 * detect IEEdge
 * returns version of IE/Edge or false, if browser is not a Microsoft browser
 */
function detectIEEdge() {
        let ua = window.navigator.userAgent;

        let msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        let trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            let rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        /*
        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
        */

        // other browser
        return false;
    }

/*
 * When any of the devise (login, signup, password reset) pages load then check if they are running an old version of IE
 * and alert if they are
 */
window.onload = function(){
    if (document.querySelectorAll(".devise-layout").length) {
        if (detectIEEdge() !== false){
            alert('This website requires a recent version of Chrome, Edge, or Firefox.');
        }
    }
};
